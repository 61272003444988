import { Container, Typography, Box } from "@mui/material";
import { useEffect } from "react";

export default function Games() {
  useEffect(() => {
    // 优化 title，包含关键词
    document.title =
      "絵文字ミックスゲーム - 無料で遊べる楽しい絵文字パズル | 人気の絵文字ミックスゲーム";
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      // 在描述中自然地使用关键词
      metaDescription.setAttribute(
        "content",
        "絵文字ミックスゲームは、絵文字を使った創造的なパズルゲームです。絵文字ミックスで絵文字同士の関連性を見つけ出し、論理的思考力を育みましょう。子供から大人まで楽しめる無料の絵文字ミックスゲームです。"
      );
    }
  }, []);

  return (
    <Container maxWidth="lg" sx={{ mt: 4 }}>
      <Typography
        variant="h1"
        sx={{
          fontSize: { xs: "2rem", md: "2.75rem" },
          mb: { xs: 2, md: 3 },
          textAlign: "center",
          fontWeight: "bold",
          background: "linear-gradient(45deg, #FF6B6B 30%, #4ECDC4 90%)",
          backgroundClip: "text",
          color: "transparent",
        }}
      >
        絵文字ミックスゲーム
      </Typography>

      <Box sx={{ mb: 4 }}>
        <Typography
          variant="body1"
          sx={{
            fontSize: { xs: "1.25rem", md: "1.5rem" },
            mb: { xs: 3, md: 4 },
            color: "text.secondary",
            textAlign: "center",
            fontWeight: 500,
            maxWidth: "800px",
            mx: "auto",
          }}
        >
          絵文字ミックス
          ゲームへようこそ！絵文字を使った創造的なパズルゲームの決定版です。
        </Typography>
        <Typography
          variant="body1"
          sx={{
            fontSize: { xs: "1rem", md: "1.125rem" },
            mb: 2,
            maxWidth: "600px",
            mx: "auto",
            color: "text.secondary",
            textAlign: "center",
            lineHeight: 1.7,
          }}
        >
          絵文字ミックスならではの面白さで、絵文字同士の関連性を見つけ出し、論理的思考力と創造力を活かして楽しみましょう。
        </Typography>
      </Box>

      <Box
        sx={{
          width: "100%",
          height: "90vh",
          maxHeight: "800px",
          border: "1px solid #ddd",
          borderRadius: 2,
          overflow: "hidden",
          my: 4,
          bgcolor: "white",
        }}
      >
        <iframe
          src="https://www.crazygames.com/embed/emoji-puzzle"
          width="100%"
          height="100%"
          frameBorder="0"
          allow="gamepad *;"
          title="絵文字ミックスゲーム - 無料の絵文字パズル"
          loading="lazy"
          aria-label="絵文字ミックス パズルゲーム - 絵文字を使った創造的なパズル"
        />
      </Box>

      <Box sx={{ mt: 4 }}>
        <Typography
          variant="h2"
          component="h2"
          sx={{
            fontSize: { xs: "1.75rem", md: "2.25rem" },
            mb: { xs: 4, md: 6 },
            textAlign: "center",
            fontWeight: "bold",
            background: "linear-gradient(45deg, #FF6B6B 30%, #4ECDC4 90%)",
            backgroundClip: "text",
            color: "transparent",
          }}
        >
          遊び方
        </Typography>

        <Box
          sx={{
            display: "grid",
            gap: { xs: 3, md: 4 },
            maxWidth: "800px",
            mx: "auto",
            mb: { xs: 6, md: 8 },
          }}
        >
          {[
            {
              title: "関連性を見つける",
              content: "異なる列にある絵文字同士の関連性を見つけ出します",
            },
            {
              title: "絵文字を結ぶ",
              content:
                "マウスの左ボタンをドラッグして、関連する絵文字同士を線で結びます",
            },
            {
              title: "組み合わせを考える",
              content:
                "例えば、「王様」と「城」、「ライオン」と「王冠」など、絵文字の意味や関係性を考えましょう",
            },
            {
              title: "ヒントを活用",
              content:
                "難しい時はヒント機能を使って、正しい組み合わせのヒントを得ることができます",
            },
          ].map(({ title, content }, index) => (
            <Box key={index}>
              <Typography
                variant="h3"
                component="h3"
                sx={{
                  fontSize: { xs: "1.2rem", md: "1.4rem" },
                  mb: 2,
                  color: "text.primary",
                  fontWeight: "bold",
                }}
              >
                {`${index + 1}. ${title}`}
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontSize: { xs: "1rem", md: "1.125rem" },
                  color: "text.secondary",
                  lineHeight: 1.7,
                }}
              >
                {content}
              </Typography>
            </Box>
          ))}
        </Box>

        <Typography
          variant="h2"
          component="h2"
          sx={{
            fontSize: { xs: "1.75rem", md: "2.25rem" },
            mb: { xs: 4, md: 6 },
            textAlign: "center",
            fontWeight: "bold",
            background: "linear-gradient(45deg, #FF6B6B 30%, #4ECDC4 90%)",
            backgroundClip: "text",
            color: "transparent",
          }}
        >
          絵文字ミックスの特徴
        </Typography>

        <Box
          sx={{
            display: "grid",
            gap: { xs: 3, md: 4 },
            maxWidth: "800px",
            mx: "auto",
            mb: { xs: 6, md: 8 },
          }}
        >
          {[
            {
              title: "直感的な操作",
              content: "マウスドラッグで簡単に絵文字を結べます",
            },
            {
              title: "様々なパズル",
              content:
                "通常の関連付けパズルの他、記憶ゲームや特別ステージなど多彩な内容",
            },
            {
              title: "IQポイントシステム",
              content:
                "ゲームを進めることでIQポイントが貯まり、レベルアップしていきます",
            },
            {
              title: "教育的要素",
              content:
                "子供から大人まで：感情知能（EQ）を育むことができる教育的な要素も含まれています",
            },
          ].map(({ title, content }, index) => (
            <Box key={index}>
              <Typography
                variant="h3"
                component="h3"
                sx={{
                  fontSize: { xs: "1.2rem", md: "1.4rem" },
                  mb: 2,
                  color: "text.primary",
                  fontWeight: "bold",
                }}
              >
                {title}
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontSize: { xs: "1rem", md: "1.125rem" },
                  color: "text.secondary",
                  lineHeight: 1.7,
                }}
              >
                {content}
              </Typography>
            </Box>
          ))}
        </Box>

        <Typography
          variant="h2"
          sx={{
            fontSize: { xs: "1.75rem", md: "2.25rem" },
            mb: { xs: 4, md: 6 },
            textAlign: "center",
            fontWeight: "bold",
            background: "linear-gradient(45deg, #FF6B6B 30%, #4ECDC4 90%)",
            backgroundClip: "text",
            color: "transparent",
          }}
        >
          絵文字ミックスゲームの対応環境
        </Typography>

        <Box
          sx={{
            display: "grid",
            gap: { xs: 3, md: 4 },
            maxWidth: "800px",
            mx: "auto",
          }}
        >
          {[
            "Webブラウザ（デスクトップ・モバイル）",
            "iOS（App Store）",
            "Android（Google Play）",
          ].map((text, index) => (
            <Typography
              key={index}
              variant="body1"
              sx={{
                fontSize: { xs: "1rem", md: "1.125rem" },
                color: "text.secondary",
                lineHeight: 1.7,
                display: "flex",
                alignItems: "flex-start",
                "&:before": {
                  content: '"•"',
                  minWidth: "2rem",
                  fontWeight: "bold",
                  color: "primary.main",
                },
              }}
            >
              {text}
            </Typography>
          ))}
        </Box>
      </Box>
    </Container>
  );
}
