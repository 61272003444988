import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Box, ThemeProvider, createTheme, Typography } from "@mui/material";
import {
  amber,
  blue,
  cyan,
  deepOrange,
  deepPurple,
  green,
  indigo,
  lightBlue,
  lightGreen,
  lime,
  orange,
  pink,
  purple,
  teal,
} from "@mui/material/colors";
import Header from "./Components/Header";
import Features from "./Components/Features";
import Kitchen from "./Components/kitchen";
import Examples from "./Components/Examples";
import HowToUse from "./Components/HowToUse";
import FAQ from "./Components/FAQ";
import Footer from "./Components/footer";
import Games from "./Components/Games";
import Navbar from "./Components/Navbar";
import Meanings from "./Components/Meanings";

// 🌈
const colors = [
  amber,
  blue,
  cyan,
  deepOrange,
  deepPurple,
  green,
  indigo,
  lightBlue,
  lightGreen,
  lime,
  orange,
  pink,
  purple,
  teal,
];

const theme = createTheme({
  palette: {
    primary: colors[Math.floor(Math.random() * colors.length)],
  },
});

function App() {
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <Box
          sx={{
            minHeight: "100dvh",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Navbar />
          <Routes>
            <Route
              path="/"
              element={
                <>
                  <div className="sr-only">
                    <Header />
                    <Features />
                  </div>
                  <Box
                    sx={{
                      flex: 1,
                      position: "relative",
                      mt: { xs: 4, md: 10 },
                      mb: { xs: 4, md: 6 },
                    }}
                  >
                    <Kitchen />
                  </Box>

                  <Box
                    sx={{
                      borderTop: "1px solid",
                      borderColor: "divider",
                      pt: { xs: 6, md: 8 }, // 增加上边距
                      mt: { xs: 4, md: 6 }, // 增加与上方内容的间距
                    }}
                  >
                    <Typography
                      variant="h2"
                      sx={{
                        fontSize: { xs: "1.5rem", md: "2rem" },
                        mb: { xs: 3, md: 4 },
                        textAlign: "center",
                        fontWeight: "bold",
                        background:
                          "linear-gradient(45deg, #FF6B6B 30%, #4ECDC4 90%)",
                        backgroundClip: "text",
                        color: "transparent",
                      }}
                    >
                      使い方ビデオ
                    </Typography>

                    <Box
                      sx={{
                        position: "relative",
                        width: "100%",
                        maxWidth: "800px",
                        mx: "auto",
                        paddingTop: "56.25%",
                        borderRadius: 2,
                        overflow: "hidden",
                        boxShadow:
                          "0 4px 6px -1px rgba(0,0,0,0.1), 0 2px 4px -1px rgba(0,0,0,0.06)",
                      }}
                    >
                      <iframe
                        src="https://www.youtube.com/embed/hA3M6tW8JPY"
                        title="絵文字ミックスゲームの使い方"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        loading="lazy"
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                        }}
                      />
                    </Box>
                  </Box>

                  <Examples />
                  <HowToUse />
                  <FAQ />
                  <Footer />
                </>
              }
            />
            <Route path="/games" element={<Games />} />
            <Route path="/meanings" element={<Meanings />} />
          </Routes>
        </Box>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
